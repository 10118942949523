import { opacityStyle } from '~/styles/opacityStyle';
import { mediaQueries } from '~/styles/mediaQueries';
import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { colors } from '~/styles/colors';

export const stepperContainer = css`
  margin-bottom: 24px;
`;

export const outerContainer = css`
  ${mediaQueries.mobile} {
    padding: 16px;
  }
  ${mediaQueries.desktop} {
    padding: 64px;
  }

  background: ${colors.white};
  box-shadow: 0 4px 8px rgba(0 0 0 / 10%);
  border-radius: 8px;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;

  ${mediaQueries.desktop} {
    gap: 56px;
  }
  ${mediaQueries.mobile} {
    gap: 40px;
  }
`;

export const title = {
  base: css`
    ${textCss({ size: 'l', weight: 'bold' })}
  `,
  centering: css`
    align-self: center;
  `,
};

export const subTitle = css`
  ${textCss({ size: 's', weight: 'regular' })}

  margin-top: -24px;
`;

export const icon = css`
  width: 96px;
  align-self: center;
`;

export const section = {
  title: css`
    ${textCss({ size: 'm', weight: 'bold' })}

    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  `,
  subTitle: css`
    ${textCss({ size: 's', weight: 'regular' })}

    margin-bottom: 16px;
    line-height: 18px;
  `,
  content: css`
    line-height: 24px;
  `,
};

export const back = css`
  ${textCss({ size: 's', color: colors.gray4 })}

  margin-top: -8px;
  cursor: pointer;

  &:hover {
    ${opacityStyle.translucent}
  }

  & > img {
    width: 10px;
    margin-right: 4px;
  }
`;
