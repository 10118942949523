import React from 'react';
import * as styles from './index.styles';

import TimeeIcon from '~/assets/logo/with-icon.svg';
import { applicationConfig } from '~/constants/applicationConfig';

export type Props = {
  children: React.ReactNode;
};

export const RegisterLayout: React.FC<Props> = (props: Props) => {
  return (
    <div css={styles.outerContainer}>
      <div css={styles.container}>
        <div css={styles.logoTitle.container}>
          <img css={styles.logoTitle.logo} src={TimeeIcon} alt="タイミー" />
          <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
        </div>

        {props.children}
      </div>
    </div>
  );
};
