import React from 'react';
import { Stepper } from '~/components/layouts/Stepper';
import * as styles from './index.styles';
import ArrowIcon from '~/assets/icon/arrow-left.svg';

export type Props = {
  currentStep: number;
  steps: string[];
  title: string;
  subTitle?: string;
  centeringTitle?: boolean;
  back?: {
    label: string;
    onClick: () => void;
  };
  descriptionIcon?: string;
  descriptionIconAlt?: string;
  sections: {
    title?: string;
    subTitle?: React.ReactNode;
    besideTitle?: React.ReactNode;
    node: React.ReactNode;
  }[];
};

export const SectionBase: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div css={styles.stepperContainer}>
        <Stepper currentStep={props.currentStep} steps={props.steps} />
      </div>

      <section css={styles.outerContainer}>
        {props.back && (
          <div onClick={props.back.onClick} css={styles.back}>
            <img src={ArrowIcon} alt={''} />
            {props.back.label}
          </div>
        )}

        <div css={styles.container}>
          <div css={[styles.title.base, props.centeringTitle && styles.title.centering]}>
            {props.title}
          </div>

          {props.subTitle && <div css={styles.subTitle}>{props.subTitle}</div>}

          {props.descriptionIcon && (
            <img
              src={props.descriptionIcon}
              css={styles.icon}
              alt={props.descriptionIconAlt || ''}
            />
          )}

          {props.sections.map(({ title, subTitle, besideTitle, node }, idx) => (
            <div key={idx}>
              {title && (
                <div css={styles.section.title}>
                  {title}
                  {besideTitle}
                </div>
              )}
              {subTitle && <div css={styles.section.subTitle}>{subTitle}</div>}
              <div css={styles.section.content}>{node}</div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
